import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby-plugin-intl"
import Language from "./language"


const NavBar = ({ intl, nav }) => (<>
    <div
        style={{
            float: `right`,
            marginTop: '10px'
        }}
    >
        <Language />
    </div>


    <span style={{
        float: `right`,
        marginRight: '30px',
        marginTop: '10px'
    }}>

        <Link
            to="/about"
            style={{
                color: `white`,
                textDecoration: `none`,
                marginRight: '15px'
            }}
        >{nav.about}</Link>

        <Link
            to="/services"
            style={{
                color: `white`,
                textDecoration: `none`,
                marginRight: '15px'
            }}
        >{nav.services}</Link>

        <Link
            to="/courses"
            style={{
                color: `white`,
                textDecoration: `none`,
                marginRight: '15px'
            }}
        >{nav.courses}</Link>

        <Link
            to="/references"
            style={{
                color: `white`,
                textDecoration: `none`,
                marginRight: '15px'
            }}
        >{nav.references}</Link>

        <Link
            to="/contact"
            style={{
                color: `white`,
                textDecoration: `none`,
                marginRight: '15px'
            }}
        >{nav.contact}</Link>


    </span>

</>

)

export default injectIntl(NavBar)
