import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  es: "Español",
}

const Language = ({isMobile}) => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            // eslint-disable-next-line
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: isMobile ? '#1d3461' : 'white',
                marginRight: 10,
                textDecoration: currentLocale === language ? `underline` : `none`,
                cursor: `pointer`,
              }}
            >
              {languageName[language]}
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
