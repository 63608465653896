/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"

import Header from "./header"
import "./layout.css"

import logoIcf from '../images/logo_icf.png';
import logoPcc from '../images/logo_pcc.jpg';

const Layout = ({ children, intl, isStart }) => {
  // eslint-disable-next-line
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let nav = {
    about: intl.formatMessage({ id: "about.title" }),
    services: intl.formatMessage({ id: "services.title" }),
    references: intl.formatMessage({ id: "references.title" }),
    courses: intl.formatMessage({ id: "courses.title" }),
    contact: intl.formatMessage({ id: "contact.title" })
  };

  return (
    <div style={{ background: isStart ? 'rgb(131, 142, 198)' : 'white' }}>
      <Header siteTitle={intl.formatMessage({ id: "title" })} siteSubTitle={intl.formatMessage({ id: "subtitle" })} nav={nav} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0
        }}
      >

        <div className="icf-wrapper">
          <span className="icf-logo-container">
            <img src={logoPcc} alt="Logo PCC" />
          </span>
          <span className="icf-logo-container">
            <img src={logoIcf} alt="Logo ICF" />
          </span>
        </div>
        

        <main style={{minHeight: 1000}}>{children}</main>
        <footer style={{ color: isStart ? 'white' : 'inherit' }}>
          <small>
            <br /><br /><br />
          Copyright &copy; {new Date().getFullYear()}&nbsp;&nbsp;
          {intl.formatMessage({ id: "author" })}&nbsp;&nbsp;-&nbsp;&nbsp;
          {intl.formatMessage({ id: "copyright" })}
          &nbsp;&nbsp;
          <Link
              className={isStart ? 'textlink-light' : 'textlink-dark'}
              to="/legal"
            >{intl.formatMessage({ id: "legal.title" })}</Link>
          &nbsp;&nbsp;
          <Link
              className={isStart ? 'textlink-light' : 'textlink-dark'}
              to="/privacy"
            >{intl.formatMessage({ id: "privacy.title" })}</Link>
          </small>
        </footer>
      </div>
    </div >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
