import { Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import NavBar from "./navbar"
import Burger from "./burger"
import "./layout.css"

const Header = ({ siteTitle, siteSubTitle, nav }) => (
  <header
    style={{
      background: `#1d3461`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0, display: "inline-block" }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
        <div style={{
          fontSize: `15px`,
          color: `white`
        }}>{siteSubTitle}</div>
      </h1>
      <span className="navbar-header">
        <NavBar nav={nav} />
      </span>
      <span className="burger-header">
        <Burger nav={nav} />
      </span>


    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSubTitle: ``,
}

export default Header
