import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby-plugin-intl"
import { slide as Menu } from 'react-burger-menu'
import Language from "./language"
import "./burger.css"

const Burger = ({ intl, nav }) => {

    const [menuOpen, setMenuOpen] = useState(false);

    var isMenuOpen = function (state) {
        setMenuOpen(state.isOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    }


    return (
        <Menu right width={'100%'} noOverlay
            isOpen={menuOpen}
            onStateChange={isMenuOpen}
        >
            <Link
                to="/about"
                className="textlink-burger"
                onClick={() => closeMenu()}
            >{nav.about}</Link>

            <Link
                to="/services"
                className="textlink-burger"
                onClick={() => closeMenu()}
            >{nav.services}</Link>

            <Link
                to="/references"
                className="textlink-burger"
                onClick={() => closeMenu()}
            >{nav.references}</Link>

            <Link
                to="/contact"
                className="textlink-burger"
                onClick={() => closeMenu()}
            >{nav.contact}</Link>


            <Language isMobile />

        </Menu>);
}

export default injectIntl(Burger)
